import React, { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Tabs,
  Tag,
  Typography
} from 'antd';
import { IconClose } from '@aws-amplify/ui-react/internal';
import { Link, useSearchParams } from 'react-router-dom';

import './QuoteDetails.less';
import { CheckCircleFilled, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  KpiName,
  useKpiUnit
} from 'src/components/project/explore/filters/KpiFilter';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useUpdateProject from 'src/Mutation/useUpdateProject';
import { findProperty, isTdsFoodGrade } from 'src/utils/properties';
import prettyNumber from 'src/components/utils/prettyNumber';
import dayjs from 'dayjs';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import { currencyValuePrice } from 'src/utils/currency';
import useGetPopulatedProject from 'src/Query/useGetPopulatedProject';
import numericRange from 'src/components/utils/numericRange';
import { ProjectTDSStatus } from 'src/components/project/search/constants';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';

const capacityText = (tdsOrProject) =>
  tdsOrProject.capacity && tdsOrProject.capacity > 0
    ? `${prettyNumber(tdsOrProject.capacity)} ${tdsOrProject.capacity_units}`
    : '--';

const locationText = (tds) =>
  tds.locations[0] ? locationRender(tds.locations[0]) : '--';

const termsText = (terms) =>
  typeof terms === 'string' || terms instanceof String
    ? terms.toUpperCase()
    : '--';
export function QuoteDetails({ onClose, project, tds }) {
  const [tab, setTab] = useState('price');
  const isConcierge = useIsConcierge();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const filters = {
    status: [status || ProjectTDSStatus.PROPOSAL]
  };

  const { mutate, messageContext } = useUpdateProject(project.uuid, filters);

  const { settings = {} } = project ?? {};

  const submittedTds = settings?.submittedTdss?.[tds.uuid] ?? null;

  const editableConfig = useMemo(
    () => (key) =>
      !isConcierge
        ? false
        : {
            onChange: (val) => {
              const updatedSettings = {
                ...settings,
                submittedTdss: {
                  ...(settings.submittedTdss ?? {}),
                  [tds.uuid]: {
                    ...(settings.submittedTdss?.[tds.uuid] ?? {}),
                    [key]: val
                  }
                }
              };

              mutate({
                uuid: project.uuid,
                settings: updatedSettings
              });
            }
          },
    [isConcierge, project, settings, tds]
  );

  const items = [
    {
      key: 'price',
      label: 'Price',
      children: <PriceTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'spec',
      label: 'Spec',
      children: <SpecTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'supply',
      label: 'Supply',
      children: <SupplyTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'brand',
      label: 'Brand',
      children: <BrandTab filters={filters} project={project} tds={tds} />
    }
  ];

  const type = findProperty(tds, 'type');
  const form = findProperty(tds, 'form');

  const tdsCapacity = capacityText(tds);
  const tdsLocation = locationText(tds);

  return (
    <Flex className="quote-details" vertical>
      <Flex
        align="center"
        className="quote-details--header mb-xl px-lg py-sm"
        justify="start"
      >
        <div className="mr-sm">
          <button className="bare" onClick={onClose} type="button">
            <IconClose />
          </button>
        </div>
        <Typography.Text>Quote Details</Typography.Text>
      </Flex>
      <Flex className="px-lg" vertical>
        <Flex className="supplier-summary mb-xl">
          <Space>
            <Avatar icon={<UserOutlined />} />
            <Flex className="ml-sm" vertical>
              <Space>
                <Typography.Text>{tds.company.name}</Typography.Text>
                {!!submittedTds?.tag && (
                  <Tag color="#2f54eb">{submittedTds?.tag}</Tag>
                )}
              </Space>
              <Typography.Text className="location">
                {tdsLocation}
              </Typography.Text>
            </Flex>
          </Space>
          <Link
            style={{ alignSelf: 'start', justifySelf: 'end' }}
            to={`/supplier/${tds.company.uuid}`}
          >
            <Button title="View Profile">View Profile</Button>
          </Link>
        </Flex>

        <Space className="supplier-assesment mb-xl">
          <Typography.Text editable={editableConfig('summary')}>
            {submittedTds?.summary ?? '(no content)'}
          </Typography.Text>
        </Space>

        <Flex className="mb-xxl quote-generals" gap={16}>
          <Flex vertical>
            <Typography.Text>Material</Typography.Text>
            <Typography.Text strong>{type.value}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Form</Typography.Text>
            <Typography.Text strong>{form.value}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Quantity</Typography.Text>
            <Typography.Text strong>{tdsCapacity}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Destination</Typography.Text>
            <Typography.Text strong>
              {locationsText(project?.locations)}
            </Typography.Text>
          </Flex>
        </Flex>

        <Tabs
          activeKey={tab}
          rootClassName="px-0"
          onChange={(key) => setTab(key)}
          items={items}
        />
      </Flex>
    </Flex>
  );
}

QuoteDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function ConciergeEditableCheck({
  filters,
  projectUuid,
  settingsKey,
  tab,
  tdsUuid
}) {
  const isConcierge = useIsConcierge();

  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { settings = {} } = project ?? {};

  const checked = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[tab]?.[settingsKey] ?? false,
    [settings, tdsUuid]
  );

  return isConcierge ? (
    <EditableCheckIcon
      filters={filters}
      projectUuid={projectUuid}
      settingsKey={settingsKey}
      tab={tab}
      tdsUuid={tdsUuid}
    />
  ) : checked ? (
    <CheckCircleFilled className="check-circle-filled success mr-xs" />
  ) : (
    <CheckCircleFilled className="check-circle-filled warning mr-xs" />
  );
}

ConciergeEditableCheck.propTypes = {
  filters: PropTypes.object,
  projectUuid: PropTypes.string.isRequired,
  settingsKey: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  tdsUuid: PropTypes.string.isRequired
};

const KpiRow = React.memo(({ filters, kpiCode, project, tds }) => {
  const kpiFilters = project?.filters?.kpis ?? {};
  const kpiFilter = kpiFilters[kpiCode];
  const defaultUnits = useKpiUnit(kpiCode);
  const kpiUnit = kpiFilter.units || defaultUnits;

  const settingsKey = `${kpiCode.toLowerCase()}_match`;

  const mnp = tds.material_numerical_properties.find(
    (propertyValue) => propertyValue.property.code === kpiCode
  );

  return (
    <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
      <Col span={12}>
        <Typography.Text>
          <KpiName codeOrUuid={kpiCode} /> ({kpiUnit || ''})
        </Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text type="secondary">
          {kpiFilter
            ? numericRange({
                min: kpiFilter.min,
                max: kpiFilter.max,
                rangeRender: (vMin, vMax) => `${vMin} - ${vMax}`,
                noMaxRender: (v) => `${v} or higher`,
                noMinRender: (v) => `${v} or lower`,
                empty: ''
              })
            : '--'}
        </Typography.Text>
      </Col>
      <Col span={6}>
        {!!mnp && (
          <>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey={settingsKey}
              tab="spec"
              tdsUuid={tds.uuid}
            />

            <Typography.Text className="heavy" strong>
              {numericRange({
                min: mnp.min,
                max: mnp.max,
                rangeRender: (vMin, vMax) => `${vMin} - ${vMax}`,
                noMaxRender: (v) => `${v} or higher`,
                noMinRender: (v) => `${v} or lower`,
                empty: ''
              })}
            </Typography.Text>
          </>
        )}
      </Col>
    </Row>
  );
});

KpiRow.displayName = 'KpiRow';

KpiRow.propTypes = {
  filters: PropTypes.object,
  kpiCode: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function PriceTab({ project, tds }) {
  const sortedPrices = tds.price_logs.sort(
    (pl1, pl2) => dayjs(pl1.created_at) > dayjs(pl2.created_at)
  );
  const latestPriceObj = sortedPrices.length
    ? sortedPrices[sortedPrices.length - 1]
    : null;
  const { price: latestPrice = null } = latestPriceObj ?? {};

  const { capacity_lbs } = project;

  const { currency, fee, freight, tariff } = tds.project_tds ?? {};

  const allInPrice =
    capacity_lbs && latestPrice
      ? capacity_lbs * latestPrice + fee || 0.0 + freight || 0.0 + tariff || 0.0
      : null;

  const projectPrice = project?.price;
  const projectPriceUnits = project?.price_weight_units;
  const isInRange = latestPrice ? latestPrice <= projectPrice : false;
  return (
    <div className="price-tab">
      <Flex vertical>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8} offset={8}>
            <Typography.Text type="secondary">Requested</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text type="secondary">Supplier</Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Unit Price</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>
              {currencyValuePrice(
                projectPrice,
                project.currency,
                projectPriceUnits
              )}
            </Typography.Text>
          </Col>
          <Col span={8}>
            <CheckCircleFilled
              className={`check-circle-filled ${
                isInRange ? 'success' : 'warning'
              } mr-xs`}
            />
            <Typography.Text className="heavy" strong>
              {latestPriceObj
                ? currencyValuePrice(
                    latestPriceObj.price,
                    latestPriceObj.currency,
                    latestPriceObj.units
                  )
                : null}
            </Typography.Text>
          </Col>
        </Row>
        {!!allInPrice && (
          <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
            <Col span={8}>
              <Typography.Text>All in price</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>--</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text className="heavy" strong>
                {currencyValuePrice(allInPrice, currency)}
              </Typography.Text>
            </Col>
          </Row>
        )}
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Pricing Terms</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>
              {termsText(project?.pricing_terms)}
            </Typography.Text>
          </Col>
          <Col span={8}>
            {!!project?.pricing_terms && (
              <Typography.Text className="heavy" strong>
                {termsText(latestPriceObj?.terms)}
              </Typography.Text>
            )}
          </Col>
        </Row>
      </Flex>
    </div>
  );
}

PriceTab.propTypes = {
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function SpecTab({ filters, project, tds }) {
  const kpiFilters = project?.filters?.kpis ?? {};
  const primaryKpis = [];
  const secondaryKpis = [];
  Object.keys(kpiFilters).forEach((code) => {
    if (!kpiFilters[code].allow_null) {
      primaryKpis.push(code);
    } else {
      secondaryKpis.push(code);
    }
  });

  return (
    <div className="spec-tab">
      <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
        <Col span={12}>
          <h6 className="ant-typography heavy mb-0">Primary Specifications</h6>
        </Col>
        <Col span={6}>
          <Typography.Text type="secondary">Requested</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text type="secondary">Supplier</Typography.Text>
        </Col>
      </Row>
      <Flex vertical>
        {primaryKpis.map((kpiCode) => (
          <KpiRow
            filters={filters}
            key={kpiCode}
            kpiCode={kpiCode}
            project={project}
            tds={tds}
          />
        ))}
      </Flex>
      {secondaryKpis.length > 0 && (
        <>
          <Divider className="mt-sm" />
          <h6 className="ant-typography heavy mb">Secondary Specifications</h6>
          <Flex vertical>
            {secondaryKpis.map((kpiCode) => (
              <KpiRow
                filters={filters}
                key={kpiCode}
                kpiCode={kpiCode}
                project={project}
                tds={tds}
              />
            ))}
          </Flex>
        </>
      )}
    </div>
  );
}

SpecTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

const isProjectFoodGrade = (project) =>
  (project?.filters?.properties?.grade || []).includes('food_grade');

const locationsText = (locations) =>
  Array.isArray(locations) ? locations.map(locationRender).join(' | ') : '--';

const booleanText = (b) => (b ? 'Yes' : '--');

function SupplyTab({ filters, project, tds }) {
  const isFoodGrade = isTdsFoodGrade(tds);
  const tdsCapacity = capacityText(tds);
  const projectCapacity = capacityText(project);
  return (
    <div className="supply-tab">
      <Flex vertical>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8} offset={8}>
            <Typography.Text>Requested</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>Supplier</Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Food Grade</Typography.Text>
          </Col>
          <Col span={8}>{booleanText(isProjectFoodGrade(project))}</Col>
          <Col span={8}>
            {isFoodGrade && (
              <CheckCircleFilled className="check-circle-filled success mr-xs" />
            )}
            <Typography.Text className="heavy" strong>
              {booleanText(isFoodGrade)}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Shipment Origin</Typography.Text>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {(project?.locations ?? []).map((location, idx) => (
                <Typography.Text key={`${locationRender(location)}-${idx}`}>
                  {locationRender(location)}
                </Typography.Text>
              ))}
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {(tds?.locations ?? []).map((location, idx) => (
                <Typography.Text
                  key={`${locationRender(location)}-${idx}`}
                  strong
                >
                  {locationRender(location)}
                </Typography.Text>
              ))}
            </Flex>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Capacity</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>{projectCapacity}</Typography.Text>
          </Col>
          <Col span={8}>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey="capacity_match"
              tab="supply"
              tdsUuid={tds.uuid}
            />
            <Typography.Text className="heavy" strong>
              {tdsCapacity}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </div>
  );
}

SupplyTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function BrandTab({ filters, project, tds }) {
  const yearsInBusiness = tds?.company?.years_in_business || '--';
  const supplierCerts = tds?.material_properties
    .filter((p) => p.meta_property.code === 'certs')
    .map((p) => p.value);
  const projectCerts = useMetaPropertyOptionNameFromCode(
    project?.filters?.properties?.certs
  );
  return (
    <div className="brand-tab">
      <Flex vertical>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8} offset={8}>
            <Typography.Text>Requested</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>Supplier</Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Certifications</Typography.Text>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {(projectCerts ?? []).map((cert) => (
                <Typography.Text key={cert} strong>
                  {cert}
                </Typography.Text>
              ))}
            </Flex>
          </Col>
          <Col span={8}>
            <Flex align="start">
              <ConciergeEditableCheck
                filters={filters}
                projectUuid={project.uuid}
                settingsKey="certs_match"
                tab="brand"
                tdsUuid={tds.uuid}
              />
              <Flex vertical>
                {(supplierCerts ?? []).map((cert) => (
                  <Typography.Text key={cert} strong>
                    {cert}
                  </Typography.Text>
                ))}
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Years in business</Typography.Text>
          </Col>
          <Col span={8}>&nbsp;</Col>
          <Col span={8}>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey="years_in_business_match"
              tab="brand"
              tdsUuid={tds.uuid}
            />
            <Typography.Text className="heavy" strong>
              {yearsInBusiness}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </div>
  );
}

BrandTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function EditableCheckIcon({
  filters,
  projectUuid,
  settingsKey,
  tab,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { mutate, messageContext } = useUpdateProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[tab]?.[settingsKey] ?? false,
    [settings, settingsKey, tab, tdsUuid]
  );

  const setMatch = useCallback(
    (newValue) => {
      const submittedTdsSettings = settings?.submittedTdss?.[tdsUuid];
      const updatedSettings = {
        ...settings,
        submittedTdss: {
          ...(settings?.submittedTdss ?? {}),
          [tdsUuid]: {
            ...(submittedTdsSettings ?? {}),
            [tab]: {
              ...submittedTdsSettings?.[tab],
              [settingsKey]: newValue
            }
          }
        }
      };

      mutate({
        uuid: projectUuid,
        settings: updatedSettings
      });
    },
    [projectUuid, settings, settingsKey, tab, tdsUuid]
  );

  return (
    <>
      {messageContext}
      <CheckCircleFilled
        className={`check-circle-filled mr-xs ${match ? 'success' : 'warning'}`}
        onClick={(ev) => {
          ev.stopPropagation();
          setMatch(!match);
        }}
      />
    </>
  );
}

EditableCheckIcon.propTypes = {
  filters: PropTypes.object,
  projectUuid: PropTypes.string.isRequired,
  settingsKey: PropTypes.string.isRequired,
  tab: PropTypes.string,
  tdsUuid: PropTypes.string.isRequired
};
