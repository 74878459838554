import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPriceLog } from 'src/Mutation';
import { useAvailableUnitsForType, usePreferredUnits } from 'src/utils/units';

export default function LogPricingForm({ tdsId }) {
  const [form] = Form.useForm();

  const weightUnits = useAvailableUnitsForType('weight');
  const defaultUnits = usePreferredUnits('weight');
  const selectedUnits = Form.useWatch('units', form);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: createPriceLog,
    onSuccess: () => {
      queryClient.invalidateQueries(['price-log']);
      queryClient.invalidateQueries(['warehouse', 'technical-data-sheet']);
      form.resetFields();
    }
  });

  if (!tdsId) return null;

  return (
    <Form
      onFinish={async (values) => {
        await form.validateFields();

        mutate({
          ...values,
          date: values.date.format('YYYY-MM-DD'),
          tds: tdsId
        });
      }}
      form={form}
      layout="vertical"
      disabled={isLoading}
    >
      <Row gutter={[16, 0]}>
        <Col xs={8}>
          <Form.Item label="Date" name="date" rules={[{ required: true }]}>
            <DatePicker style={{ height: 40 }} format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            label="Unit Price"
            name="price"
            rules={[{ required: true }]}
          >
            <Input
              style={{ height: 40 }}
              addonAfter={
                weightUnits && defaultUnits ? (
                  <Form.Item
                    initialValue={defaultUnits}
                    name="units"
                    rules={[{ required: true }]}
                    noStyle
                  >
                    <Select
                      style={{ width: 65 }}
                      options={weightUnits?.map((unit) => ({
                        label: `$/${unit}`,
                        value: unit
                      }))}
                    />
                  </Form.Item>
                ) : (
                  ''
                )
              }
            />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item dependencies={['units']} label="Volume" name="quantity">
            <Input
              style={{ height: 40 }}
              size="small"
              addonAfter={selectedUnits}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">
        <PlusOutlined /> {isLoading ? 'Logging...' : 'Log Transaction'}
      </Button>
    </Form>
  );
}

LogPricingForm.propTypes = {
  tdsId: PropTypes.string
};
