import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd';
import React, { useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
/* eslint-enable import/no-unresolved */

import FiltersSidebar from 'src/components/project/FiltersSidebar';
import { ConditionalContent as ExploreContent } from 'src/components/project/explore/Content';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { createProject, updateProject } from 'src/Mutation';

import 'src/pages/project/styles/Explore.less';
import ProjectHeader from 'src/components/project/ProjectHeader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = 'Roboto';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.color = '#63748D';
ChartJS.defaults.plugins.title.color = '#323640';
ChartJS.defaults.plugins.title.font.size = 18;
ChartJS.defaults.plugins.title.font.weight = '500';
ChartJS.defaults.plugins.title.align = 'start';
ChartJS.defaults.plugins.title.padding = 0;

export default function ProjectExplore() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const newProject = searchParams.get('new');
  const projectUuid = searchParams.get('project');

  const [newProjectModalIsOpen, setNewProjectModalIsOpen] =
    useState(newProject);

  const [form] = Form.useForm();
  const typeProperty = useMetaProperty('type');

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      setSearchParams({ project: response.uuid });
      setNewProjectModalIsOpen(false);
    },
    onError: (e) => {
      setNewProjectModalIsOpen(false);
      // Send error to Sentry
      captureException(e);
    }
  });

  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      navigate(`/project/search?project=${response.uuid}`);
    },
    onError: (e) => {
      navigate(`/project/search?project=${projectUuid}`);
      // Send error to Sentry
      captureException(e);
    }
  });

  return (
    <div className="explore-page">
      <ProjectHeader
        right={
          projectUuid ? (
            <Button
              onClick={() =>
                updateProjectMutation({ uuid: projectUuid, stage: 'search' })
              }
              type="primary"
              htmlType="button"
              disabled={updating}
            >
              {updating ? 'Loading...' : 'Share & Continue'}
            </Button>
          ) : (
            <Link to="/project/search/">
              <Button type="primary" htmlType="button">
                Share & Continue
              </Button>
            </Link>
          )
        }
      />
      <Row wrap={false}>
        <Col flex="239px">
          <FiltersSidebar />
        </Col>
        <Col flex="1" style={{ maxWidth: 'calc(100% - 239px)' }}>
          <div className="content explore-content">
            <ExploreContent />
          </div>
        </Col>
      </Row>
      <Modal
        width={500}
        title="Create Project"
        open={newProjectModalIsOpen}
        onCancel={() => setNewProjectModalIsOpen(false)}
        okText={isLoading ? 'Creating project...' : 'Create Project'}
        okButtonProps={{ loading: isLoading }}
        onOk={form.submit}
        // eslint-disable-next-line react/no-unstable-nested-components
        footer={(_, { OkBtn }) => (
          <Space direction="vertical" size={12}>
            <OkBtn />
            <span>
              Or{' '}
              <Button
                style={{ padding: 0 }}
                htmlType="button"
                type="link"
                size="small"
                onClick={() => setNewProjectModalIsOpen(false)}
              >
                continue without saving
              </Button>
            </span>
          </Space>
        )}
      >
        <Form
          validateMessages={{
            required: 'This field is required.'
          }}
          layout="vertical"
          form={form}
          disabled={isLoading}
          onFinish={async (values) => {
            await form.validateFields();

            mutateAsync(values);
          }}
        >
          <p>Name your project to save your exploration.</p>
          <Form.Item label="Name this project" name="name">
            <Input />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 40 }}
            label="Pick a material"
            name={['filters', 'type_code__in']}
            rules={[{ required: true }]}
            disabled={!typeProperty}
          >
            <Select
              options={typeProperty?.options?.map((o) => ({
                label: o.value,
                value: o.code
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
