import {
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  message,
  Typography,
  Popconfirm
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link
} from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { getProject } from 'src/Query';
import { deleteProject, updateProject } from 'src/Mutation';
import ProjectSettings from 'src/pages/project/ProjectSettings';
import useIsConcierge from 'src/hooks/useIsConcierge';

export default function ProjectHeader({ title, right }) {
  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  const navigate = useNavigate();
  const isConcierge = useIsConcierge();

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Successfully saved project.'
      });
    },
    onError: (e) => {
      // Send error to Sentry
      captureException(e);
      messageApi.open({
        type: 'error',
        content: 'An error ocurred. Please try again.'
      });
    }
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');
  const { data: project } = useQuery(
    ['project', projectUuid],
    () => getProject(projectUuid),
    { enabled: !!projectUuid }
  );
  useEffect(() => {
    if (project?.filters) {
      setExploreCtx({
        ...exploreCtx,
        filters: project.filters,
        projectSettings: project.settings || {},
        kpis: project.filters.kpis || {}
      });
    }
  }, [project]);

  // Store filters in URL for easy sharing
  const [filters] = useConciergeContextState(['explore', 'filters']);
  useEffect(() => {
    setSearchParams((params) => {
      if (filters) params.set('filters', JSON.stringify(filters));

      return params;
    });
  }, [filters]);

  // Populate filters from URL
  const filtersFromURL = searchParams.get('filters');
  useEffect(() => {
    if (filtersFromURL && filtersFromURL !== JSON.stringify(filters)) {
      const parsedFilters = JSON.parse(filtersFromURL);
      if (Object.keys(parsedFilters).length > 0)
        setExploreCtx({
          ...exploreCtx,
          filters: parsedFilters,
          kpis: parsedFilters.kpis || {}
        });
    }
  }, [filtersFromURL]);

  const location = useLocation();
  const { mutate: deleteProjectMutation } = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);

      navigate(location.pathname);

      setExploreCtx({ filters: {}, projectSettings: {}, kpis: {} });

      messageApi.open({
        type: 'success',
        content: 'Successfully deleted project.'
      });
    },
    onError: (e) => {
      navigate(location.pathname);
      // Send error to Sentry
      captureException(e);
    }
  });

  const [editingProject, setEditingProject] = useState();

  const renameProjectHandler = () => {
    setEditingProject(true);
  };
  const deleteProjectHandler = () => {
    deleteProjectMutation(projectUuid);
  };

  const projectMenuItems = [
    ...(isConcierge
      ? [
          {
            key: 'drfq',
            label: (
              <Link to={`/digital-rfq/${projectUuid}`} target="_blank">
                <button className="bare" type="button">
                  Digital RFQ
                </button>
              </Link>
            )
          },
          {
            key: 'django',
            label: (
              <Link
                to={`${process.env.REACT_APP_CIRCLE_API}/admin/api/project/${projectUuid}/`}
                target="_blank"
              >
                <button className="bare" type="button">
                  View in Django
                </button>
              </Link>
            )
          },
          { type: 'divider' }
        ]
      : []),
    {
      key: '1',
      label: (
        <button
          onClick={() => renameProjectHandler()}
          className="bare"
          type="button"
        >
          Rename
        </button>
      )
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title="Delete project"
          description="Are you sure you want to delete this project?"
          onConfirm={deleteProjectHandler}
          okText="Delete"
          cancelText="Cancel"
          okType="danger"
        >
          <button
            // Dont' close dropdown
            onClick={(e) => e.stopPropagation()}
            className="bare"
            type="button"
          >
            <DeleteOutlined /> Delete Project
          </button>
        </Popconfirm>
      ),
      danger: true
    }
  ];

  return (
    <>
      {contextHolder}
      <Row justify="space-between" align="bottom" className="project-header">
        <Col>
          {project?.updated_at ? (
            <Typography.Paragraph
              style={{ margin: 0 }}
              className="font-size-2"
              type="secondary"
            >
              Last Updated: {dayjs(project?.updated_at).fromNow()}
            </Typography.Paragraph>
          ) : null}
          <Typography.Title style={{ marginTop: 0 }} level={3}>
            {project?.name || title || 'Explore'}
          </Typography.Title>
        </Col>
        <Col>
          <Space direction="horizontal" size={12}>
            {projectUuid ? (
              <Button
                loading={isLoading}
                htmlType="button"
                onClick={() =>
                  mutate({
                    uuid: project.uuid,
                    filters: exploreCtx?.filters,
                    settings: {
                      // TODO: ask James about overwriting project settings here
                      ...project.settings,
                      ...exploreCtx?.projectSettings
                    }
                  })
                }
              >
                {isLoading ? 'Saving...' : 'Save Project'}
              </Button>
            ) : (
              <Button htmlType="button" onClick={() => setEditingProject(true)}>
                Save As New Project
              </Button>
            )}
            {right}
            {projectUuid ? (
              <Dropdown
                menu={{ items: projectMenuItems }}
                placement="bottomLeft"
              >
                <Button htmlType="button">
                  <MoreOutlined />
                </Button>
              </Dropdown>
            ) : null}
          </Space>
        </Col>
      </Row>
      <ProjectSettings
        open={editingProject}
        close={() => setEditingProject(false)}
        uuid={projectUuid}
        callback={(response) => setSearchParams({ project: response.uuid })}
        projectData={{
          stage: window.location.pathname.includes('search')
            ? 'search'
            : 'explore'
        }}
      />
    </>
  );
}
ProjectHeader.propTypes = {
  title: PropTypes.string,
  right: PropTypes.node
};
