import React, { useMemo } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';

import { getGeoAnalytics } from 'src/Query/warehouse';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { DEFAULT_DEBOUNCE_TIME } from 'src/hooks/useDebouncedQuery';
import { SupplierMarker } from 'src/pages/maps/SupplierMarker';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

import useMap from './hooks/useMap';
import { MapContext } from './MapContext';
import { SupplierCountMarker } from './SupplierCountMarker';

const ZOOM_LEVEL_TDS = 'supplier';
const ZOOM_LEVEL_STATE = 'state';
const ZOOM_LEVEL_COUNTRY = 'country';
const ZOOM_LEVEL_REGION = 'region';

const EMPTY_ARRAY = [];

// for the popup on a marker, we can get away with raw html
const popupHtml = (spot) => `<div>
<h3 style="margin: 4px;">${spot.label}</h3>
<table style="padding: 8px"><tbody>
<tr><td>Suppliers</td><td style="padding: 0 8px; text-align: right">${
  spot.suppliers
}</td></tr>
<tr><td>Materials</td><td style="padding: 0 8px; text-align: right">${
  spot.tdss
}</td></tr>
<tr><td>Total Capacity</td><td style="padding: 0 8px; text-align: right">${prettyNumberRound(
  spot.total_capacity_lbs / 2204
)} mt</td></tr>
<tr><td>Verified Capacity</td><td style="padding: 0 8px; text-align: right">${prettyNumberRound(
  spot.verified_capacity_lbs / 2204
)} mt</td></tr>
</tbody></table>
</div>`;

export default function MapPageWarehouse(props) {
  const { MapComponent } = useMapPageWarehouse(props);

  return MapComponent;
}

MapPageWarehouse.propTypes = {
  additionalMarkers: PropTypes.array,
  useMapProps: PropTypes.object
};

export function useMapPageWarehouse({
  additionalMarkers,
  useMapProps,
  className,
  ...props
}) {
  const { map, mapContainer, zoom } = useMap({
    mapProps: {
      zoom: 1 // Start zoomed out
    },
    ...(useMapProps || {})
  });
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true
  });
  const zoomLevel =
    (zoom < 3 && ZOOM_LEVEL_REGION) ||
    (zoom < 5 && ZOOM_LEVEL_COUNTRY) ||
    (zoom < 7 && ZOOM_LEVEL_STATE) ||
    ZOOM_LEVEL_TDS;

  const supplyZoomLevel = zoom >= 7;
  const nextZoomLevel = (level) => {
    if (level < 3) return 3;
    if (level < 5) return 5;
    return level + 2;
  };

  const handleMarkerClick = (m, lat, lng) => {
    // new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map);
    m.flyTo({
      center: [lng, lat],
      zoom: nextZoomLevel(m.getZoom()),
      speed: 0.5
    });
  };

  const filters = useRelevantFiltersForForm();
  // We only want to debounce sidebar filters changing but not zoom changing
  const debouncedFilters = useDebounce(filters, DEFAULT_DEBOUNCE_TIME);
  const completeFilters = useMemo(
    () => ({ ...debouncedFilters, level: zoomLevel }),
    [debouncedFilters, zoomLevel]
  );

  const { data = EMPTY_ARRAY, isFetching } = useQuery(
    ['warehouse', 'geo-analytics', completeFilters],
    () => getGeoAnalytics(completeFilters)
  );
  const loadingClass = useLoadingStateClass(isFetching);

  const onMarkerMouseEnter = (spot) => {
    popup.remove();
    map.getCanvas().style.cursor = 'pointer';
    popup.setLngLat([spot.long, spot.lat]).setHTML(popupHtml(spot)).addTo(map);
  };
  const onMarkerMouseLeave = (spot) => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  };

  const bounds = map?.getBounds();
  const n = bounds?.getNorth();
  const s = bounds?.getSouth();
  const w = bounds?.getWest();
  const e = bounds?.getEast();
  const activeRecords = useMemo(
    () => {
      if (!map || !data || data.length === 0) return [];
      return data.filter(
        (o) => o.lat > s && o.lat < n && o.long > w && o.long < e
      );
    },
    [map, data, n, s, w, e]
    /* eslint-enable react/no-array-index-key */
  );
  const markers = useMemo(
    () => {
      if (!activeRecords || activeRecords.length === 0)
        return additionalMarkers || [];
      return [
        ...activeRecords.map((spot) =>
          supplyZoomLevel ? (
            <SupplierMarker
              supplier={{
                lat: spot.lat,
                long: spot.long
              }}
              primary={spot.verified > 0}
              id={spot.id}
              label={spot.label}
              key={spot.id}
              map={map}
              data={spot}
              handleMarkerClick={handleMarkerClick}
              onMouseEnter={() => onMarkerMouseEnter(spot)}
              onMouseLeave={() => onMarkerMouseLeave(spot)}
              suppliers={spot.suppliers}
            />
          ) : (
            <SupplierCountMarker
              supplier={{
                lat: spot.lat,
                long: spot.long
              }}
              primary={spot.verified > 0}
              id={spot.id}
              label={spot.label}
              key={spot.id}
              map={map}
              data={spot}
              handleMarkerClick={handleMarkerClick}
              onMouseEnter={() => onMarkerMouseEnter(spot)}
              onMouseLeave={() => onMarkerMouseLeave(spot)}
              suppliers={spot.suppliers}
            />
          )
        ),
        ...(additionalMarkers || [])
      ];
    },
    [activeRecords, additionalMarkers]
    /* eslint-enable react/no-array-index-key */
  );

  return {
    map,
    activeRecords,
    MapComponent: (
      <MapContext.Provider value={map}>
        <div
          ref={mapContainer}
          {...props}
          className={`risk-map ${className} ${loadingClass}`}
        >
          {markers}
        </div>
      </MapContext.Provider>
    )
  };
}
