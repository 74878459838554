import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Row,
  Skeleton,
  Col,
  Space,
  Typography,
  Form,
  Input,
  InputNumber,
  Popover,
  Alert,
  Grid
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useParentSize } from '@visx/responsive';
import { captureException } from '@sentry/react';
import dayjs from 'dayjs';

// React PDF
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { getProject } from 'src/Query';
import Logo from 'src/components/Logo';
import useManageTds from 'src/pages/supplier/useManageTds';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { useUser } from 'src/utils/authentication';
import { updateCompany } from 'src/Mutation';
import parseApiError, { ApiErrorAlert } from 'src/utils/parseApiError';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { parseInternalLocation } from 'src/components/form/LocationInput';
import PriceQuoteForm from 'src/components/supplier/PriceQuoteForm';
import {
  useEditSupplierCapabilityMutation,
  useSupplierCapabilityTds
} from 'src/pages/supplier/useEditSupplierProfile';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function DigitalRfq() {
  const navigate = useNavigate();

  const bps = Grid.useBreakpoint();

  const { data: user } = useUser();

  const { projectId } = useParams();
  const { data: project, error } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    { enabled: !!projectId }
  );

  const materialType = useMetaProperty('type', project?.filters?.type_code__in);
  const materialForm = useMetaProperty('form', project?.filters?.form_code__in);

  const [supplierForm] = Form.useForm();
  const [supplierErrors, setSupplierErrors] = useState();
  const { mutate: patchCompany, isLoading: isMutatingCompany } = useMutation({
    mutationFn: updateCompany,
    onError: (e) => {
      const errorObj = e.detail;
      const fieldErrors = parseApiError(errorObj, form);

      setSupplierErrors(fieldErrors);
      captureException(e);
    }
  });

  const defaults = useMemo(
    () => ({
      material_properties_ids: {
        type: materialType?.uuid,
        form: materialForm?.uuid
      }
    }),
    [materialType?.uuid, materialForm?.uuid]
  );

  const [priceQuote, setPriceQuote] = useState();

  const {
    FormComponent,
    form,
    error: tdsErrors,
    isMutating
  } = useManageTds({
    afterSubmit: ({ uuid }) =>
      navigate(`/digital-rfq/submission-confirmed?tds=${uuid}`),
    defaults,
    additionalTdsParams: {
      send_email: true,
      projects: [projectId],
      ...(!priceQuote?.price
        ? {}
        : {
            price_logs: [
              {
                date: dayjs().format('YYYY-MM-DD'),
                ...priceQuote
              }
            ]
          })
    }
  });

  const [capabilityTds] = useSupplierCapabilityTds(user?.company?.uuid);
  const { mutate: createOrUpdateCapability } =
    useEditSupplierCapabilityMutation((errorObj) => {
      const fieldErrors = parseApiError(errorObj, form);
      setSupplierErrors(fieldErrors);
    });

  const loadingClass = useLoadingStateClass(isMutating || isMutatingCompany);
  const [numPages, setNumPages] = useState();
  const { parentRef, width } = useParentSize({
    initialSize: { width: 750 }
  });

  const allErrors = [...(supplierErrors || []), ...(tdsErrors || [])];

  if (error)
    return (
      <Alert
        type="error"
        message="Error fetching project"
        action={
          <Link to="https://circular.co">
            <Button>Go to homepage</Button>
          </Link>
        }
      />
    );

  return (
    <div className={`digital-rfq ${loadingClass}`}>
      <Row
        className="digital-rfq--header"
        justify="space-between"
        align="middle"
        wrap={false}
      >
        <Col>
          <Logo />
        </Col>
        <Col>
          <Space direction="horizontal" size={bps.sm ? 12 : 4}>
            <Popover
              title="How it works"
              content={
                <>
                  <Typography.Paragraph>
                    The information you provide in response to this request for
                    quote will be shared directly with the buyer. The price
                    quote you provide will be considered relevant only for this
                    specific opportunity.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    In addition, Circular.co will use your product
                    specifications to match you to future opportunities with
                    buyers on our platform. The more information you provide
                    about the products you sell, the more likely you are to
                    surface in search results.
                  </Typography.Paragraph>
                </>
              }
            >
              <Button type="text">
                <Typography.Text strong type="secondary">
                  <QuestionCircleOutlined />
                  <span className="show-sm"> How does it work?</span>
                </Typography.Text>
              </Button>
            </Popover>
            <Button
              onClick={() => {
                Promise.all([
                  supplierForm.validateFields(),
                  form.validateFields()
                ])
                  .then(() => {
                    supplierForm.submit();
                    form.submit();
                  })
                  .catch(() =>
                    setSupplierErrors(['Please complete all required fields'])
                  );
              }}
              type="primary"
              disabled={!project}
            >
              Send Response
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="digital-rfq--body" wrap={false}>
        <Col lg={16} xxl={18} className="digital-rfq--content show-lg">
          <div className="digital-rfq--document-wrapper" ref={parentRef}>
            <Document
              onLoadSuccess={(data) => setNumPages(data.numPages)}
              file={project?.rfq_file}
              noData={!project ? 'Loading PDF..' : 'No PDF file specified.'}
            >
              {Array(numPages)
                .fill('')
                .map((_, i) => (
                  <Page key={i} width={width} pageNumber={i + 1} />
                ))}
            </Document>
          </div>
        </Col>
        <Col xs={24} lg={8} xxl={6} className="digital-rfq--sidebar">
          {!project || !user ? (
            <Skeleton />
          ) : (
            <div className="digital-rfq--sidebar--inner">
              {project?.rfq_file && (
                <a
                  className="hide-lg"
                  title="View RFQ File"
                  href={project?.rfq_file}
                  target="_blank"
                >
                  <Button
                    block
                    type="primary"
                    htmlType="button"
                    className="mb-lg"
                  >
                    View Request
                  </Button>
                </a>
              )}
              <Typography.Title level={4}>
                {project.company} is requesting information about your products
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Respond by answering the required questions and attaching a TDS.
              </Typography.Paragraph>
              <ApiErrorAlert errors={allErrors} />
              <Form.Item
                label="Supplier Name"
                labelCol={{ span: 24 }}
                initialValue={user.company.name}
                extra="If you wish to modify your company's name, please email us."
              >
                <Input value={user.company.name} disabled />
              </Form.Item>
              <Form
                form={supplierForm}
                onFinish={(values) => {
                  const tdsLocations = form.getFieldValue('locations');
                  const material = form.getFieldValue([
                    'material_properties_ids',
                    'type'
                  ]);
                  const capacity = form.getFieldValue('capacity');
                  const capacity_units = form.getFieldValue('capacity_units');

                  const patchData = {
                    uuid: user.company.uuid,
                    locations_add: tdsLocations.map((loc) =>
                      parseInternalLocation(loc)
                    ),
                    supply_capacity: capacity,
                    supply_capacity_units: capacity_units
                  };

                  if (values.years_in_business)
                    patchData.year_established = dayjs()
                      .subtract(values.years_in_business, 'y')
                      .format('YYYY');

                  patchCompany(patchData);
                  createOrUpdateCapability({
                    material_properties_ids: [
                      ...(capabilityTds
                        ? capabilityTds.material_properties.map(
                            ({ uuid }) => uuid
                          )
                        : []),
                      material
                    ],
                    is_capability: true,
                    source: 'supplier_direct',
                    company_id: user.company.uuid,
                    uuid: capabilityTds?.uuid
                  });
                }}
              >
                <Form.Item
                  label="Years in Business"
                  labelCol={{ span: 24 }}
                  name="years_in_business"
                  initialValue={parseInt(user.company.years_in_business)}
                >
                  <InputNumber />
                </Form.Item>
              </Form>
              {FormComponent}
              <Typography.Title level={4}>Price Quote</Typography.Title>
              <Alert
                type="info"
                showIcon
                message="TDSs with a price quote are more likely to be selected."
                className="mb-sm"
              />
              <PriceQuoteForm onValuesChange={setPriceQuote} />
              <ApiErrorAlert errors={allErrors} />
              <Button
                className="hide-lg"
                onClick={() => {
                  Promise.all([
                    supplierForm.validateFields(),
                    form.validateFields()
                  ])
                    .then(() => {
                      supplierForm.submit();
                      form.submit();
                    })
                    .catch(() =>
                      setSupplierErrors(['Please complete all required fields'])
                    );
                }}
                type="primary"
                disabled={!project}
              >
                Send Response
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
