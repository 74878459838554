import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Input, Skeleton, Alert } from 'antd';
import React, { useMemo, useState } from 'react';
import { createOrUpdateTds, updateCompany } from 'src/Mutation';
import { getCompany, getTDSs } from 'src/Query';
import CertificationSelect from 'src/components/form/CertificationSelect';
import MetaPropertySelect from 'src/components/form/MetaPropertySelect';
import MultipleLocationInput, {
  parseInternalLocation
} from 'src/components/form/MultipleLocationInputSelect';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import UploadDocuments from 'src/components/form/UploadDocuments';
import { useUser } from 'src/utils/authentication';
import parseApiError from 'src/utils/parseApiError';

/**
 * Returns the supplier's capability TDS w a source of `supplier_direct`
 * @param {String} supplierId
 */
export function useSupplierCapabilityTds(supplierId) {
  const tdsFilters = {
    company__uuid: supplierId,
    is_capability: true,
    source: 'supplier_direct'
  };
  const { data: capabilityTdssData, isLoading } = useQuery(
    ['technical-data-sheet', tdsFilters],
    () => getTDSs(tdsFilters),
    { enabled: !!supplierId }
  );
  const capabilityTds = useMemo(
    () => capabilityTdssData?.results?.[0],
    [capabilityTdssData]
  );

  return [capabilityTds, isLoading];
}

export function useEditSupplierCapabilityMutation(onError) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createOrUpdateTds,
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['warehouse', 'technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['warehouse', 'supplier-capability']
      });
    },
    onError: (e) => {
      const errorObj = e.detail;

      window.console.error('Error updating company capabilities', e, errorObj);

      if (onError) onError(errorObj);
    }
  });
}

export default function useEditSupplierProfile({
  supplierId: passedSupplierId,
  hideTdsUpload,
  extraFields,
  afterSubmit,
  sendEmails
}) {
  const [error, setError] = useState();
  const { data: user } = useUser();

  const supplierId = passedSupplierId || user?.company?.uuid;

  const { data: supplier } = useQuery(['company', supplierId], () =>
    getCompany(supplierId)
  );

  const [form] = Form.useForm();

  const [capabilityTds, isLoadingCapabilityTds] =
    useSupplierCapabilityTds(supplierId);

  const queryClient = useQueryClient();

  const { mutateAsync: patchCompany, isLoading: isMutatingCompany } =
    useMutation({
      mutationFn: updateCompany,
      onSuccess: (response) => {
        queryClient.invalidateQueries({
          queryKey: ['company', response.uuid]
        });
      },
      onError: (e) => {
        const errorObj = e.detail;

        const fieldErrors = parseApiError(errorObj, form);

        setError(fieldErrors);

        window.console.error('Error updating company', e, errorObj);
      }
    });

  const { mutateAsync: mutateTds, isLoading: isMutatingTds } =
    useEditSupplierCapabilityMutation((errorObj) => {
      const fieldErrors = parseApiError(errorObj, form);
      setError(fieldErrors);
    });

  const isMutating = isMutatingCompany || isMutatingTds;

  // Since we use these as initialValues, we shouldn't render the form until they're loaded
  const FormComponent =
    !supplier || !user || isLoadingCapabilityTds ? (
      <Skeleton />
    ) : (
      <Form
        form={form}
        initialValues={{ ...supplier, email: supplier.email || user.email }}
        onFinish={async (values) => {
          setError(null);

          await form.validateFields();

          let { website } = values;

          if (
            website &&
            !(website.startsWith('http://') || website.startsWith('https://'))
          ) {
            website = `http://${website}`;
          }

          // Update company
          await patchCompany({
            ...values,
            website,
            locations: values?.locations?.map(parseInternalLocation),
            phone_number: values?.phone_number?.replace(/[()-\s]/g, ''),
            uuid: supplier.uuid,
            send_email: sendEmails
          });

          // Create / update capability TDS
          const capabilityPromise = values?.capability
            ? mutateTds({
                ...values.capability,
                is_capability: true,
                source: 'supplier_direct',
                company_id: supplierId,
                uuid: capabilityTds?.uuid
              })
            : Promise.resolve();

          // Create individual TDSs for each uploaded doc
          const tdsPromises = values?.documents?.length
            ? values.documents.map(({ uuid: docUuid }) =>
                mutateTds({
                  is_capability: false,
                  source: 'supplier_direct',
                  documents_ids: [docUuid],
                  send_email: sendEmails
                })
              )
            : [Promise.resolve()];

          await Promise.all([capabilityPromise, ...tdsPromises]);

          if (afterSubmit) afterSubmit();
        }}
        layout="vertical"
        validateMessages={{
          required: 'This field is required.'
        }}
        disabled={isMutating}
        scrollToFirstError
        onValuesChange={(values) => {
          Object.keys(values).forEach((field) => {
            const fieldError = form.getFieldError(field);
            if (!fieldError.length) {
              return;
            }
            // Clear error message of field
            form.setFields([
              {
                name: field,
                errors: []
              }
            ]);
          });
        }}
      >
        <Row gutter={[20, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name="name"
              label="Company Name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name="contact_name"
              label="Contact Name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name="email"
              label="Email"
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name="phone_number"
              label="Phone"
            >
              <Input type="tel" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              label="Website"
              name="website"
            >
              <Input type="url" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              label="Locations"
              name="locations"
            >
              <MultipleLocationInput />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              name={['capability', 'material_properties_ids']}
              label="Materials Produced"
              initialValue={capabilityTds?.material_properties
                ?.filter(({ meta_property: { code } }) => code === 'type')
                ?.map(({ uuid }) => uuid)}
            >
              <MetaPropertySelect propertyName="type" multiple />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: true
                }
              ]}
              label="Capacity"
              name="supply_capacity"
            >
              <QuantityWithUnitsInput unitsFieldName="supply_capacity_units" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Certifications"
              name="certifications_ids"
              initialValue={supplier.certifications.map(({ uuid }) => uuid)}
            >
              <CertificationSelect multiple showSearch />
            </Form.Item>
          </Col>
          {hideTdsUpload ? null : (
            <Col xs={24}>
              <Form.Item
                label="Upload TDS's"
                extra="Uploading TDS’s will allow Circular to provide material information to buyers on the platform. This makes it easier for buyers to search and find your products."
                name="documents"
              >
                <UploadDocuments />
              </Form.Item>
            </Col>
          )}
          {extraFields}
        </Row>
      </Form>
    );

  const ErrorAlert = error ? (
    <Alert
      message={error.map((err) => (
        <>
          {err}
          <br />
        </>
      ))}
      type="error"
      style={{ marginBottom: 16 }}
    />
  ) : null;

  return {
    form,
    FormComponent,
    error,
    ErrorAlert,
    isMutating
  };
}
