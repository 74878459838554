import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Flex,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography
} from 'antd';
import React, { useMemo, useState } from 'react';
import { CheckCircleFilled, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import FullScreenModal from 'src/components/layout/FullScreenModal';
import ProposalSummary from 'src/components/project/search/ProposalSummary';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import { QuoteDetails } from 'src/components/project/search/QuoteDetails';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import useUpdateProject from 'src/Mutation/useUpdateProject';
import useGetPopulatedProject from 'src/Query/useGetPopulatedProject';
import useGetProjectWorkflows from 'src/Query/useGetProjectWorkflows';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { ProjectTDSStatus } from 'src/components/project/search/constants';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import dayjs from 'dayjs';
import { isTdsFoodGrade } from 'src/utils/properties';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import { UnitSelect } from 'src/utils/units';

const tabQuestionMap = {
  brand: ['certs_match', 'years_in_business_match'],
  price: [],
  spec: [],
  supply: ['capacity_match']
};

export const matchesForTab = (project, tds, tab) => {
  if (!project?.settings?.submittedTdss?.[tds.uuid]?.[tab]) {
    return tab === 'supply' && isTdsFoodGrade(tds) ? 1 : 0;
  }

  const tdsTabContents = project.settings.submittedTdss[tds.uuid][tab];

  const questions = tabQuestionMap[tab];
  let matchCount = questions.reduce(
    (acc, question) => acc + (tdsTabContents[question] ? 1 : 0),
    0
  );
  const kpiCodes = Object.keys(project.filters?.kpis ?? {});

  switch (tab) {
    case 'spec':
      return (
        matchCount +
        kpiCodes.reduce((acc, kpiCode) => {
          const key = `${kpiCode.toLowerCase()}_match`;
          return acc + (tdsTabContents[key] ? 1 : 0);
        }, 0)
      );
    case 'supply':
      matchCount += isTdsFoodGrade(tds) ? 1 : 0;
      return matchCount;
    default:
      return matchCount;
  }
};

export const questionsForTab = (project, tds, tab) => {
  const questions = tabQuestionMap[tab];
  const kpiCodes = Object.keys(project.filters?.kpis ?? {});

  switch (tab) {
    case 'spec':
      return kpiCodes.length;
    case 'supply':
      return questions.length + 1; // Food grade is not stored in the project settings
    default:
      return questions.length;
  }
};

export const tdsPriceText = (tds) => {
  const sortedPrices = tds.price_logs.sort(
    (pl1, pl2) => dayjs(pl1.created_at) > dayjs(pl2.created_at)
  );
  const latestPriceObj = sortedPrices.length
    ? sortedPrices[sortedPrices.length - 1]
    : null;
  const { price: latestPrice = null } = latestPriceObj ?? {};
  return latestPrice ? `$${prettyNumberRound(latestPrice)}` : '--';
};

export default function ProposalModal() {
  const navigate = useNavigate();
  const isConcierge = useIsConcierge();
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');
  const status = searchParams.get('status');

  const [showingTdsId, setShowingTdsId] = useState(null);

  const filters = {
    status: [status || ProjectTDSStatus.PROPOSAL]
  };

  const {
    data: project,
    isLoading,
    isRefetching
  } = useGetPopulatedProject(projectUuid, filters);

  const { data: searchTDSs } = useGetWarehouseTdss(project?.filters, {
    enabled: !!project
  });

  const { settings } = project ?? {};

  const {
    mutate,
    isLoading: isUpdatingProject,
    messageContext
  } = useUpdateProject(projectUuid, filters);

  const { data: projectWorkflows, isLoadingWorkflows } =
    useGetProjectWorkflows(projectUuid);

  const {
    wfExited: _workflowExited,
    wfIn: _workflowIn,
    wfMetGoal: workflowMetGoal,
    wfTotal: workflowTotal
  } = useMemo(() => {
    let wfExited = 0;
    let wfIn = 0;
    let wfMetGoal = 0;
    let wfTotal = 0;

    (projectWorkflows || []).forEach((wf) => {
      wfExited += wf.analytics.exited.value;
      wfIn += wf.analytics.in.value;
      wfMetGoal += wf.analytics.met_goal.value;
      wfTotal += wf.analytics.total.value;
    });

    return {
      wfExited,
      wfIn,
      wfMetGoal,
      wfTotal
    };
  }, [projectWorkflows]);

  const loadingClass = useLoadingStateClass(isUpdatingProject || isRefetching);

  const responseTDSs = useMemo(() => project?.tdss ?? [], [project]);

  const searchResultCount = searchTDSs?.count || 0;

  const editableConfig = useMemo(
    () => (key) =>
      !isConcierge
        ? false
        : {
            onChange: (val) =>
              mutate({
                uuid: projectUuid,
                settings: {
                  ...(settings ?? {}),
                  [key]: val
                }
              })
          },
    [isConcierge, settings]
  );

  const RightSideContent = useMemo(
    () => (
      <>
        {isConcierge && (
          <AdminConsoleLink app="api" type="project" uuid={projectUuid}>
            <Button title="Edit in Admin">Edit in Admin</Button>
          </AdminConsoleLink>
        )}
        <UnitSelect
          unitType="weight"
          invalidateQueries={[['project', projectUuid, 'populated']]}
        />
        <Link target="_blank" to={`/digital-rfq/${projectUuid}`}>
          <Button title="View Digital RFQ">View Digital RFQ</Button>
        </Link>
      </>
    ),
    [projectUuid]
  );

  if (isLoading) {
    return (
      <FullScreenModal
        title="Project Quotes"
        width="100%"
        open
        onBack={() => navigate(`/project/search?project=${projectUuid}`)}
        className={`search--proposal ${loadingClass}`}
        headerProps={{
          right: RightSideContent
        }}
      >
        <Skeleton />
      </FullScreenModal>
    );
  }

  return (
    <>
      {messageContext}
      <FullScreenModal
        title="Project Quotes"
        width="100%"
        open
        onBack={() => navigate(`/project/search?project=${projectUuid}`)}
        className="search--proposal"
        headerProps={{
          right: RightSideContent
        }}
      >
        <Flex
          align="stretch"
          className={loadingClass}
          direction="horizontal"
          justify="center"
          style={{ minHeight: '100%' }}
        >
          <Flex
            className="search--proposal--list pb-2xxl"
            justify={showingTdsId ? 'end' : 'center'}
          >
            <Space
              className="mr-xl"
              direction="vertical"
              style={{ maxWidth: '960px' }}
            >
              <Typography.Title className="mb-lg mt-lg" level={3}>
                Project Quotes
              </Typography.Title>
              <div className="color-card color-card--info mb-lg">
                <Typography.Text editable={editableConfig('proposal_summary')}>
                  {settings?.proposal_summary ?? '(no content)'}
                </Typography.Text>
              </div>
              <ProposalSummary
                quotesCount={responseTDSs.length}
                respondedAmount={workflowMetGoal}
                respondedPercent={prettyNumberRound(
                  (workflowMetGoal * 100) / workflowTotal
                )}
                rfqSentCount={workflowTotal}
                rfqSentPercent={prettyNumberRound(
                  (workflowTotal * 100) / searchResultCount
                )}
                searchCount={searchResultCount}
              />
              <Space className="quotes pb" direction="vertical" size={16}>
                <Row className="header-row mx-0" gutter={[16, 0]} justify="end">
                  <BoxColumn>
                    <Typography.Text type="secondary">Price</Typography.Text>
                  </BoxColumn>
                  <BoxColumn>
                    <Typography.Text type="secondary">Specs</Typography.Text>
                  </BoxColumn>
                  <BoxColumn>
                    <Typography.Text type="secondary">Supply</Typography.Text>
                  </BoxColumn>
                  <BoxColumn>
                    <Typography.Text type="secondary">Brand</Typography.Text>
                  </BoxColumn>
                </Row>
                {responseTDSs
                  // For some reason the .map function is giving a `cannot read properties of undefined` if we don't filter?
                  .filter((tds) => !!tds.company)
                  .map((tds) => (
                    <Row
                      key={tds.uuid}
                      className="mx-0 single-quote"
                      gutter={[16, 0]}
                      wrap={false}
                      onClick={() => setShowingTdsId(tds.uuid)}
                    >
                      <Col flex="1">
                        <Row gutter={[12, 0]} wrap={false} align="middle">
                          <Col>
                            <Avatar
                              icon={<UserOutlined />}
                              src={tds.company.avatar}
                            />
                          </Col>
                          <Col>
                            <div>
                              <Typography.Text
                                style={{ lineHeight: '20px' }}
                                strong
                              >
                                {tds.company.name}
                              </Typography.Text>
                              {!!settings?.submittedTdss?.[tds.uuid]?.tag && (
                                <Tag color="#2f54eb">
                                  {settings?.submittedTdss?.[tds.uuid]?.tag}
                                </Tag>
                              )}
                            </div>
                            <div>
                              <Typography.Text
                                style={{ fontSize: 12, lineHeight: '12px' }}
                                type="secondary"
                              >
                                {locationRender(tds.locations[0])}
                              </Typography.Text>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <BoxColumn>
                        <EditableMatchIcon
                          filters={filters}
                          projectUuid={projectUuid}
                          settingsKey="price_match"
                          tdsUuid={tds.uuid}
                        >
                          <Typography.Text strong>
                            {tdsPriceText(tds)}
                          </Typography.Text>
                        </EditableMatchIcon>
                      </BoxColumn>
                      <BoxColumn>
                        <EditableMatchIconXOverY
                          filters={filters}
                          projectUuid={projectUuid}
                          settingsKey="specs_match"
                          tdsUuid={tds.uuid}
                          x={matchesForTab(project, tds, 'spec')}
                          y={questionsForTab(project, tds, 'spec')}
                        />
                      </BoxColumn>
                      <BoxColumn>
                        <EditableMatchIconXOverY
                          filters={filters}
                          projectUuid={projectUuid}
                          settingsKey="supply_match"
                          tdsUuid={tds.uuid}
                          x={matchesForTab(project, tds, 'supply')}
                          y={questionsForTab(project, tds, 'supply')}
                        />
                      </BoxColumn>
                      <BoxColumn>
                        <EditableMatchIconXOverY
                          filters={filters}
                          projectUuid={projectUuid}
                          settingsKey="brand_match"
                          tdsUuid={tds.uuid}
                          x={matchesForTab(project, tds, 'brand')}
                          y={questionsForTab(project, tds, 'brand')}
                        />
                      </BoxColumn>
                    </Row>
                  ))}
              </Space>
            </Space>
          </Flex>
          {!!showingTdsId && (
            <Flex align="start" className="search--proposal--details" vertical>
              <QuoteDetails
                onClose={() => setShowingTdsId(null)}
                project={project}
                tds={responseTDSs.find(({ uuid }) => uuid === showingTdsId)}
              />
            </Flex>
          )}
        </Flex>
      </FullScreenModal>
    </>
  );
}
ProposalModal.propTypes = {};

const matchOptions = [
  {
    key: 'match',
    label: 'Match',
    icon: <CheckCircleFilled className="check-circle-filled success" />
  },
  {
    key: 'nomatch',
    label: 'No match',
    icon: <CheckCircleFilled className="check-circle-filled warning" />
  }
];

function EditableMatchIconXOverY({
  x,
  y,
  filters,
  projectUuid,
  settingsKey,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[settingsKey] ?? false,
    [settings]
  );
  const type = match ? 'success' : 'danger';
  return (
    <EditableMatchIcon
      filters={filters}
      projectUuid={projectUuid}
      settingsKey={settingsKey}
      tdsUuid={tdsUuid}
    >
      <Typography.Text strong>{x}</Typography.Text>
      <Typography.Text type={type}>{` / ${y}`}</Typography.Text>
    </EditableMatchIcon>
  );
}

EditableMatchIconXOverY.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  filters: PropTypes.object,
  projectUuid: PropTypes.string,
  settingsKey: PropTypes.string,
  tdsUuid: PropTypes.string
};

function EditableMatchIcon({
  children,
  filters,
  projectUuid,
  settingsKey,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { mutate, messageContext } = useUpdateProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[settingsKey] ?? false,
    [settings]
  );
  const type = match ? 'success' : 'danger';

  return (
    <>
      {messageContext}
      <Dropdown
        trigger="hover"
        menu={{
          items: matchOptions,
          onClick: ({ key, domEvent }) => {
            domEvent.stopPropagation();

            const updatedSettings = {
              ...settings,
              submittedTdss: {
                ...(settings?.submittedTdss ?? {}),
                [tdsUuid]: {
                  ...(settings?.submittedTdss?.[tdsUuid] ?? {}),
                  [settingsKey]: key === 'match'
                }
              }
            };

            mutate({
              uuid: projectUuid,
              settings: updatedSettings
            });
          }
        }}
      >
        <Box type={type}>{children}</Box>
      </Dropdown>
    </>
  );
}

EditableMatchIcon.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.object,
  projectUuid: PropTypes.string,
  settingsKey: PropTypes.string,
  tdsUuid: PropTypes.string
};
