import { Marker } from 'src/pages/maps/Marker';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export function SupplierCountMarker({
  supplier,
  id,
  map,
  suppliers,
  data,
  handleMarkerClick,
  primary,
  onMouseEnter,
  onMouseLeave,
  label
}) {
  useEffect(() => () => onMouseLeave());
  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={22}
      latitude={Number(supplier.lat)}
      longitude={Number(supplier.long)}
    >
      <div
        className={`map-marker-count ${primary ? 'blue' : ''}`}
        onClick={() => {
          if (handleMarkerClick)
            handleMarkerClick(map, supplier.lat, supplier.long, data);
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role="presentation"
      >
        {suppliers}
      </div>
    </Marker>
  );
}

SupplierCountMarker.propTypes = {
  supplier: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  map: PropTypes.object,
  data: PropTypes.object,
  suppliers: PropTypes.number.isRequired,
  primary: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  handleMarkerClick: PropTypes.func
};
