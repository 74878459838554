import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography } from 'antd';
import LogPricingForm from 'src/components/supplier/LogPricingForm';
import PriceLogs from 'src/components/supplier/PriceLogs';

export default function LogPricingModal({ tdsId }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} size="small" htmlType="button">
        Log Pricing
      </Button>
      <Modal
        width={817}
        open={open}
        onCancel={() => setOpen(false)}
        cancelText="Close"
        // eslint-disable-next-line react/no-unstable-nested-components
        footer={(_, { CancelBtn }) => <CancelBtn />}
        title="Log Material Transactions"
      >
        <Typography.Paragraph>
          Log your most recent transaction prices for this material, which we'll
          use to benchmark you against other comparable suppliers that you can
          reference. Going forward we recommend adding a transaction per week to
          keep prices current and relevant.
        </Typography.Paragraph>
        <LogPricingForm tdsId={tdsId} />
        <Typography.Title style={{ marginTop: 40, marginBottom: 25 }} level={4}>
          Pricing History
        </Typography.Title>
        <PriceLogs tdsId={tdsId} />
      </Modal>
    </>
  );
}

LogPricingModal.propTypes = {
  tdsId: PropTypes.string
};
